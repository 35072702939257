@import './mixin.scss';
@import './variables.scss';


.Login_Container_Fill{
    @include setting;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    .Left{
        width: 35%;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
        background-color: $blue_color;
    }
    .Right{
        width: 65%;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
        background-color: $white_color;
        position: relative;
        .Login_Header{
            @include setting;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:.5rem 2rem;
        }
        .Login_Form{
            width: 50%;
            height: auto;
            box-sizing: border-box;
            overflow: hidden;
            padding:1rem 0rem;
            position: absolute;
            top: 50%;
            left: 25%;
            transform: translate(-25%,-50%);
            h3{
                @include FormTitle;
                color: $black_color;
            }

            p{
                @include  BtnText;
                color: $grey_color;
                margin-bottom: 1rem;
            }

            form{
                width: 100%;
                height: auto;
                input{
                    width: 100%;
                    height:45px;
                    border-radius: 3px;
                    box-sizing: border-box;
                    border: 1px solid $tab_inactive_color;
                    padding:.5rem 1rem;
                    @include placeholderText;
                    color: $black_color;
                    //text-transform: capitalize;
                    outline: none;
                    &.error{
                        border-color: $error_color;
                    }
                    &::placeholder{
                        @include placeholderText;
                        color: $grey_color;
                    }
                }
                .Box{
                    margin-bottom:1.5rem;
                    &.Reset_Middle{
                      margin-bottom:1.5rem!important;
                    }
                    &.Reset_Bottom{
                      margin-bottom: .2rem;  
                    }
                    &.Request{
                      margin-bottom: .5rem;  
                    }
                    &:nth-of-type(2){
                        margin-bottom: .5rem;
                    }
                    span{
                        @include placeholderText;
                        color: $error_color; 
                    }
                }
            }
            .Forgot_Text{
                @include ForgotText;
                color: $blue_color;;
            }
            .Terms{
                @include FormTinyText;
                color: $grey_color;
                margin-top: 5rem;
                font-size: 11px;
                a{
                    color: $fade_error;
                    font-weight: bold;
                }
            }
            .Btn{
                width: 100%;
                height: 45px;
                border-radius: 3px;
                background:$linear_gradient;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                cursor: pointer;
                color: $white_color;
                margin-top: 1rem;
            }
        }
    }
    .Mobile_Phone{
        background-image: url("../Img/mobile.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width:213.18px;
        height:460.98px;
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-30%,-50%);
    }
}

@media (max-width:768px){
    .Login_Container_Fill{
        
        .Right{
            .Login_Form{
                width: 70%;
                top: 50%;
                left:61%;
                transform: translate(-50%,-50%);
            }
        }
    }
}

@media (max-width:400px){
    .Login_Container_Fill{
        justify-content: center;
        align-items: flex-start;
        .Left{
           display: none;
        }
        .Right{
            width: 100%;
            height: 100vh;
            padding-top: 1.5rem;
            padding-bottom: 3rem;
            .Login_Header{
                padding:.5rem .7rem;
            }
            .Login_Form{
                width:94%;
                height: auto;
                top: 45%;
                left:50%;
                transform: translate(-50%,-50%);
            }
        }
        .Mobile_Phone{
           display: none;
        }
    }
}
@import './mixin.scss';
@import './variables.scss';


.Scroll_Wrapper{
    width: 90%;
    margin-left: auto;
    .swiper {
        width: 100%;
        height: 650px;
        .swiper-slide {
            margin-inline:0rem;
            // border: 1px solid green;
            .Wrap_Detail {
                width: 100%;
                height: 100%;
                position: relative;
                // border: 1px solid red;
                .Float_wrap{
                    width: 85%;
                    height: 248.94px;
                    border-radius: 14.3309px;
                    background-color: $blue_color;
                    position: absolute;
                    top:0%;
                    left:5%;
                }
                .Card{
                    width: 100%;
                    height: 580px;
                    border-radius: 3px;
                    background-color: $scroll_card_bg;
                    position: absolute;
                    // border: 1px solid red;
                    bottom: 0%;
                    left: 0%;
                    .Detail_Box{
                        width: 80%;
                        height:350px;
                        position: absolute;
                        bottom: 6%;
                        left: 10%;
                        h4{
                            @include delivery_man_title;
                            color: $deep_dark;
                            margin-bottom: 1rem;
                        }
                        P{
                            @include HeroText;
                            color: $grey_color;
                        }
                    }
                }
              }
          }
    }
    .Botton_Nav{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 2.5rem;
        button{
            width: 45.86px;
            height: 45.86px;
            border-radius: 100%;
            @include flex;
            border: 1px solid $grey_color;
            background-color: transparent;
            svg{
                color: $grey_color;
            }
        }
        .Tin_line{
            width: 897.36px;
            height: 1px;
            border: 1px solid $grey_color;
        }
    }
}

@media (max-width:768px){
    .Scroll_Wrapper{
        width: 100%;
        .swiper {
            width: 100%;
            // height:630px;
            .swiper-slide {
                .Wrap_Detail {
                    .Float_wrap{
                        width: 80%;
                    }
                    .Card{
                        .Detail_Box{
                            height:350px;
                        }
                    }
                   
                  }
              }
        }
    }
}

@media (max-width:400px){
    .Scroll_Wrapper{
        width: 100%;
        .swiper {
            width: 100%;
            // height: 590px;
            .swiper-slide {
                margin-inline:0rem;
                .Wrap_Detail {
                    .Float_wrap{
                        width: 80%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .Card{
                        .Detail_Box{
                            // height:290px;
                        }
                    }
                   
                  }
              }
        }
    }
}
$linear_gradient:linear-gradient(270deg, #2561A7 0%, #E92F33 100%);
$blue_color:#225AA4;
$error_color:#FF000F;
$black_color:#000000;
$grey_color:#828282;
$powder_blue:#D1DCEC;
$light_blue:#E4EBF4;
$white_color:#FFFFFF;
$tab_inactive_color:#E0E0E0;
$tab_active_color:#E4EBF4;
$border_color:rgba(0, 0, 0, 0.05);
$inactive_tab_text:#BDBDBD;
$fade_error:#E92D38;
$tab_bg:#d1dcec7e;
$radio_bg:#FEFEFE;
$select_text_color:#4F4F4F;
$ship_card_text_color:rgba(0, 0, 0, 0.7);
$deep_dark:#000000;
$wallet_border:rgba(0, 0, 0, 0.1);
$wallet_success_btn:#219653;
$wallet_success_btn_bg:#CFEFE2;
$light_orange:#F6F0D1;
$deep_orange:#EAB761;
$light_cancel: #FDD9DD;
$deep_cancel:#E92D38;
$light_active_btn:#CFEBFF;
$deep_active_btn:#51A9F7;
$arrow_btn_color:#F2F2F2;
$hero_text_color:#F2F2F2;
$Dot_color:#D9D9D9;
$scroll_card_bg:#EAF0F9;
$Banner_Bg:#DBE8F9;
$card_shadow_color:rgba(0, 0, 0, 0.25);
$fade_orange:#F7B6BA;
$fade_blue:#BAD6FF;
$card_btn_blue:#7999C5;
$input_border:#E0E0E0;
$sidebar_color:#FFEEF1;
$purple_color:#800080;
$purple_bg: #f5e1ff;
$hero_shadow:rgba(0, 0, 0, 0.1);
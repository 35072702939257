@import './mixin.scss';
@import './variables.scss';

.GetRatesWrapper{
    width: 100%;
    height: auto;
    p {
        font-family: 'Manjari';
    }

    .InfoSection {
        width: 70%;
        padding-left: 2rem;
    }

    .RatesSection {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 6.56rem 0 3.12rem 0;
        background: #FDF6F5;
        position: relative;
        width: 100%;

        .InfoHero {
            color: #2563A3;
            // text-align: center;
            font-family: Rockwell;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1rem;
        }

        .InfoSub {
            color: #413D3D;
            // text-align: center;
            font-family: Manjari;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 3.41rem;
        }

        .RatesFormContain {
            display: flex;
            justify-content: center;
        }

        .GetRatesForm {
            padding: .5rem 0 1.12rem 0;
            position: absolute;
    
            .InfoHero {
                color: #2563A3;
                text-align: center;
                font-family: Rockwell;
                font-size: 3.375rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 2.19rem;
            }
    
            .InfoSub {
                color: #413D3D;
                text-align: center;
                font-family: Manjari;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 3.75rem;
            }
    
            .FormContain {
                height: 42.625rem; 
                border-radius: 1.5625rem;
                background: #EFF1F4;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                padding: 5.75rem 4.63rem 4.87rem 4.19rem;
    
                .FormGrid {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0,1fr));
                    margin-bottom: 3.19rem;
                    gap: 4.35rem;
                }
    
                .TextAreaContain {
                    width: 100%;
                    margin-bottom: 3.19rem;
                }
            }
    
        }

        .toggleContain {
            transition: all 500ms ease-in-out !important;
            width: 100%;
            height: 56px;
            border-radius: 45px;
            border: 1px solid #2563A3;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            margin-bottom: 3rem;
            cursor: pointer;
            position: relative;

            .toggleBtn {
                position: absolute;
                width: 50%;
                height: 100%;
                padding: .5rem 1rem;
                left: 0;
                top: 0;
                z-index: 5;

                .toggleBtnItem {
                    background-color: $error_color;
                    width: 100%;
                    height: 100%;
                    border-radius: 45px;
                }

            }

            .toggleBtn.left {
                right: 0;
                left: auto;
            }
            

            .toggleDiv {
                width: 50%;
                text-align: center;
                font-weight: 600;
            }

            .toggleDiv.active {
                z-index: 8;
                color: #EFF1F4;
            }
        }

        .BtnWrap {
            width: 33.625rem;
        }
    }
    .CarrierSection {
        margin-top: 39rem;
        margin-bottom: 4rem;
        display: flex;
        justify-content: center;
        padding-left: 9.81rem;

        .CarrierWrap {
            width: 100%;
        }

        .CarriersContain {
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            margin-bottom: 2.62rem;

            img {
                width: 72px;
                height: 72px;
            }

            .CarrierName {
                color: #000;
                font-family: Manjari;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .CarrierInfo {
                color: #413D3D;
                font-family: Manjari;
                font-size: .8rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .WorkDays {
                color: #000;
                font-family: Manjari;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .MobWorkDays {
                display: none;
            }
            .CarrierAmount {
                color: #000;
                font-family: Rockwell;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

}

@media (max-width:768px){
    .GetRatesWrapper{
        .InfoSection {
            width: 100%;
            padding-left: 0;
        }
        .RatesSection {
            display: block;
            padding: .5rem 0 3rem 0;
            position: relative;
            background: #FDF6F5;
            position: relative;
            width: 100%;

            .BtnWrap {
                width: 100%;
            }
    
            .InfoHero {
                font-size: 2rem;
                text-align: center;
                
            }
    
            .InfoSub {
                font-size: 1rem;
                font-style: normal;
                margin-bottom: 3.41rem;
                text-align: center;
            }
    
            .GetRatesForm {
                padding: .2rem 0 2.11rem 0;
                position: inherit;
                width: 95%;
        
                .InfoHero {
                    color: #2563A3;
                    text-align: center;
                    font-family: Rockwell;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 2.19rem;
                }
        
                .InfoSub {
                    color: #413D3D;
                    text-align: center;
                    font-family: Manjari;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 3.75rem;
                }
        
                .FormContain {
                    border-radius: 1.5625rem;
                    background: #EFF1F4;
                    width: 100%;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    padding: 5.75rem 1rem 4.87rem 1em;
        
                    .FormGrid {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(1, minmax(0,1fr));
                        margin-bottom: 2rem;
                        gap: 2rem;
                    }
        
                    .TextAreaContain {
                        width: 100%;
                        margin-bottom: 3.19rem;
                    }
                }
        
            }
        }
        .CarrierSection {
            margin-top: 4rem;
            margin-bottom: 2rem;
            display: flex;
            justify-content: center;
            padding-left: 1rem;
    
            .CarrierWrap {
                width: 100%;
            }
    
            .CarriersContain {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                margin-bottom: 2.62rem;

                .CarrierImg {
                    width: 68px;
                    height: 68px;
                }
    
                .CarrierName {
                    color: #000;
                    font-family: Manjari;
                    font-size: .8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: left;
                }
                .CarrierInfo {
                    color: #413D3D;
                    font-family: Manjari;
                    font-size: .7rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .MobWorkDays {
                    color: #000;
                    font-family: Manjari;
                    font-size: .7rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: block;
                }
                .DeskWorkDays {
                    display: none;
                }
                .CarrierAmount {
                    color: #000;
                    font-family: Rockwell;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                li{
                    &:nth-of-type(1){
                        margin-right:2rem;
                    }
                    &:nth-of-type(3){
                        width: 200px;
                        margin-right:1rem;
                    }
                    &:nth-of-type(2){
                        width: 300px;
                    }
                    .Box{
                        width:250px;
                    }
                }
            }
            .Footer_Bottom{
                width: 100%;
            }
        }
    }
}

@media (max-width:400px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                flex-direction: column;
                li{
                   width: 100%;
                //    &:last-child{
                    
                //     p{
                //         width: 100%;
                //         border: 1px solid red;
                //     }
                //    }
                    .Box{
                        width:100%;
                    }
                }
            }
            .Footer_Bottom{
                align-items: flex-start;
                flex-direction: column;
                .Left{
                    width: 100%;
                    margin-bottom: 1rem;
                }
                .Right{
                    width: 100%;
                }
            }

        }
    }
    .toggleContain {
        padding: 0 .4rem;

        .toggleBtn {
            padding: .4rem .5rem !important;
        }

        .toggleDiv {
            font-size: 12px;
        }
    }
}
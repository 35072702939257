@import './mixin.scss';
@import './variables.scss';


.Package_Detail{
    width: 100%;
    box-sizing: border-box;
    height: auto;
    padding: 1rem;
    background-color:$tab_bg;
    border-radius:3px;
    .Top{
        .Box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1rem;
            margin-bottom:.5rem;
            border-bottom: 1px solid $border_color;
            .circle,.Right{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $powder_blue;
            }
            .Left{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .circle{
                    width: 30px;
                    height: 30px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    color: $black_color;
                    border-radius: 100%;
                    margin-right: 1rem;
                }
                .Detail{
                    h4{
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                        color: $black_color;
                    }
                    h5{
                        font-weight: 400;
                        font-size: 10.5266px;
                        line-height: 18px;
                        color: $grey_color;
                    }
                }
            }
            .Right{
                width: 43.12px;
                height: 25.74px;
                border-radius: 17.6476px;
            }
        }
        .chip{
            width: 80.69px;
            height: 25.74px;
            border-radius: 17.6476px;
            font-weight: 700;
            font-size: 10px;
            line-height: 18px;
            color: $grey_color;
            background-color: $powder_blue;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 3rem;
            margin-bottom:2.5rem;
            cursor: pointer;
        }
    }
    h4{
        @include PackageText;
        color: $blue_color;
    }
    ul{
        width: 100%;
        margin-bottom:2.5rem;
        li{
            @include PackageText;
            color: $grey_color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 1rem 0rem;
            &:nth-of-type(1){
                border-top: 1px solid $border_color;
            }
            span{
                @include PackageNum;
                color: $black_color;
            }
            .btn{
                @include PackageNum;
                width: 43.12px;
                height: 25.74px;
                background-color: $powder_blue;
                border-radius: 17.6476px;
                color: $blue_color;
                display: flex;
                align-items: center;
                justify-content: center;
                &.change{
                    width:80px;
                }
            }
            border-bottom: 1px solid $border_color;
        }
    }
    small{
        font-weight: 400;
        font-size: 10.5266px;
        line-height: 18px;
        color: $grey_color;
    }
    button{
        @include DangerBtn;
    }
}

.Modal_Wrapper{
    width: 450px;
    height: 100%;
    .Box{
        width: 100%;
        height: auto;
        margin-bottom: .6rem;
        label{
            @include label;
            color: $black_color;
        }
        input, textarea, select{
            width: 100%;
            height: 45.02px;
            box-sizing: border-box;
            border-radius: 2.6235px;
            background-color: $white_color;
            outline: none;
            border:1px solid $tab_inactive_color;
            padding:.5rem 1rem;
            @include label;
            color: $black_color;
            font-weight: normal;
            margin-top:.3rem;
            &::placeholder{
                @include inputText;
                color: $grey_color;
            }
            &.error{
                border-color: $error_color!important;
            }
        }
        textarea{
            min-height: 100px;
        }
        span{
            @include placeholderText;
            color: $error_color; 
        }
    }
    .Top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;
    }
    span.bottom_text{
        @include placeholderText;
        color: $grey_color;
    }
    button{
        @include DangerBtn;
    }
}

.css-10u0hqt{
    outline: none!important;
    border: none!important;
   
}

@media (max-width:400px){
    .Modal_Wrapper{
        width:290px;
        .Top{
           flex-direction: column;
           align-items: flex-start;
           gap:.3rem;
        }

        .Box {
            input, textarea, select {
                width: 80vw;
            }
        }
    }
}
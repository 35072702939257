@import './mixin.scss';
@import './variables.scss';

.Card_Wrapper{
    width: 80%;
    margin-inline: auto;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 5rem;
    .Card{
    width: 325.29px;
    height: 318.81px;
    border-radius: 9.42193px;
    padding:2rem;
    background-color:  $white_color;
    position: relative;
    transition: hover .4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    &:hover{
        box-shadow: 0px 25px 50px -12px $card_shadow_color;
        .Btn{
            background-color: $blue_color;
        }
    }
    .SVG_Box{
        width: 60px;
        height: 60px;
        border-radius: 9.42193px;
        background-color: $blue_color;
        @include flex;
    }
    p{
        @include  LandCartTitle;
        color: $grey_color;
        margin:1.3rem 0rem;
        text-align: center
    }
    .Btn{
        @include LandingCardBtnText;
        @include flex;
        gap:.5rem;
        width: 122px;
        height: 35px;
        background: $card_btn_blue;
        color: $white_color;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translateX(-50%);
    }
    }
}

@media (max-width:768px){
    .Card_Wrapper{
        width:95%;
        gap:1rem;
        .Card{
        height:350px;
        }
    }
}
@media (max-width:400px){
    .Card_Wrapper{
        width:95%;
        flex-direction: column;
        gap:1rem;
        .Card{
        width: 100%;
        height:350px;
        }
    }
}

@import './mixin.scss';
@import './variables.scss';

.Shimpent_Navigate{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
    margin:1.5rem 0rem;
    margin-bottom: 5rem;
    .Btn{
        @include flex;
        width: 43.55px;
        height: 43.55px;
        border-radius: 100%;
        background-color: $arrow_btn_color;
    }
}



@import './mixin.scss';
@import './variables.scss';


.Tripple_Card_Wrapper{
    width: 100%;
    height: auto;
    background-color: $scroll_card_bg;
    padding-top: 3rem;
    padding-bottom: 5rem;
}
@import './mixin.scss';
@import './variables.scss';

.Dashboard_Wrapper{
    width: 70%;
    height: auto;
    box-sizing: border-box;
    margin:5rem auto;
    
    h3{
        @include DashbaoardWelcomeText;
        color: $black_color;
        margin-bottom: 1rem;
    }
    .Banner, .CreateBanner{
        width: 100%;
        height: 213px;
        border-radius: 3px;
        background-color: $powder_blue;
        background-image: url("../Img/truck.png");
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0rem;
        .Inner_Card{
            width: 313.4px;
            height: auto;
            margin-left:2rem;
           .Top_Box,.Bottom_Box{
            width: 100%;
            height:  74.46px;
            border-radius: 2px;
           }
           .Top_Box{
            background-color: $blue_color;
            margin-bottom:.5rem;
            display: flex;
            align-items: center;
            justify-content:space-between;
            box-sizing: border-box;
            padding-inline: 1rem;
            .Wrap{
                display: flex;
                align-items:flex-end;
                justify-content: flex-start;
                h2{
                    @include BalanceText;
                    color: $white_color;
                }
                h6{
                    @include BalanceTinyText;
                    color: $white_color; 
                 }
            }
            h4{
                @include BalanceTinyText; 
                color: $white_color;
            }
            
            .Right{
                width: 18.92px;
                height: 18.89px;
                background-color: $white_color;
                color: $blue_color;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
           }
           .Bottom_Box{
            background-color: #ffffff8c;
            display: flex;
            align-items: center;
            justify-content:space-between;
            box-sizing: border-box;
            padding-inline: 1rem;
            h3{
                @include BalanceText;
                color:$black_color;
                margin-top: 1rem;
            }
            .Right{
                display: flex;
                align-items: center;
                gap: .3rem;
                .Btn{
                    width: 91.13px;
                    height: 18.89px;
                    background-color:$white_color;
                    color: $black_color;
                    @include BalanceTinyText;
                    border-radius:2px;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .Img_Box{
                width: 18.92px;
                height: 18.89px;
                background-color:$blue_color;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                }
            }
           }
        }
    }

    .CreateBanner {
        height: auto;
        padding-top: 2rem;

        .viewLink {
            font-size: 12px;
            color: #ffffff;
            font-size: 500;
        }
    }
    .Cards_Wrapper{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 1rem;
        margin-top: 1rem;
    }
}

.Custom_Card{
    @include setting;
    height:154.33px;
    border-radius: 3px;
    background-color:$light_blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.active{
        background: $linear_gradient;
        .Inner_Card{
            
            .Img_Box{
                background-color: $white_color;
                svg{
                    fill:  $blue_color;
                }
            }
            .Detail_Box{
                h3{
                    color: $white_color;
                    svg{
                        fill:$white_color;
                    }
                }
                p{
                    color: $white_color;
                }
            }
        } 
    }
    .Inner_Card{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        padding-left: 3rem;
        .Img_Box{
            width: 58.09px;
            height: 58.38px;
            background-color: $blue_color;
            border-radius: 2.61577px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                fill: $white_color;
            }
        }
        .Detail_Box{
            h3{
                @include CardTitle;
                color: $black_color;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg{
                    fill: $black_color;
                }
            }
            p{
                @include CardText;
                color: $black_color;
            }
        }
    }
}

@media (max-width:768px){
    .Dashboard_Wrapper{
        width: 95%;
        .Banner, .CreateBanner {
            background-image: none;
        }
        .Cards_Wrapper{
            .Custom_Card{
             .Inner_Card{
                padding-left: 1rem;
             }
            }
        }
    }
}

@media (max-width:400px){
    .Dashboard_Wrapper{
        width: 95%;
        .Cards_Wrapper{
           grid-template-columns: repeat(1,1fr);
        }
    }
}
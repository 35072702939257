@import './mixin.scss';
@import './variables.scss';


.Local_Shipment_Wrapper {
  width: 70%;
  height: auto;
  box-sizing: border-box;
  margin: 5rem auto;

  .Tabs_Wrap {
    .Tab_Card {
      height: 87.83px;
      box-sizing: border-box;
      border-radius: 2.61577px;
      background-color: $tab_inactive_color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 1rem;
      cursor: pointer;
      margin-bottom: 1rem;

      &.active {
        background-color: $tab_active_color;

        .Left {
          h4 {
            @include CardTitle;
            color: $blue_color;
          }

          h5 {
            @include TabSubText;
            color: $grey_color;
          }
        }
      }

      .Left {
        h4 {
          @include CardTitle;
          color: $inactive_tab_text;
        }

        h5 {
          @include TabSubText;
          color: $inactive_tab_text;
        }
      }

    }
  }
  
  .Dynamic_Display_Wrap{
    width: 100%;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Price_Param{
      @include HeroTitle;
      color: $black_color;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h3 {
    @include DashbaoardWelcomeText;
    color: $black_color;
    margin-bottom: 1rem;
    display:flex;
    align-items: center;
    gap:.5rem;
  }

  .Wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.5rem;

    .Left {
      width: 50%;

      .Terms {
        @include FormTinyText;
        color: $grey_color;
        margin-top: 3rem;
        font-size: 11px;

        a {
          color: $error_color;
          font-weight: bold;
        }
      }
    }

    .Right {
      width: 50%;

      .Terms {
        @include FormTinyText;
        color: $grey_color;
        margin-top: 1rem;
        font-size: 11px;

        a {
          color: $error_color;
          font-weight: bold;
        }
      }

      form {
        width: 100%;
        height: 450;
        background-color: $tab_bg;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 1rem 2rem;

        input[type="text"]{
          text-transform: capitalize!important;
        }
        input[type="submit"], .btn {
          @include DangerBtn;
          outline: none;
          border: none;
          margin-top: 2rem;
          cursor: pointer;
        }
        .Price_Btn{
          @include DangerBtn;
          outline: none;
          border: none;
          margin-top:.6rem;
          cursor: pointer;
        }

        .Top {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .Radio_Box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 1rem;

          label {
            @include label;
            color: $black_color;
          }

          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            width: 10px;
            height: 10px;
            border: 1px solid $grey_color;
            border-radius: 50%;
            margin-bottom: .2rem;
            margin-right: 1rem;

            &:focus {
              outline: 2px solid $blue_color;
              outline-offset: 3px;
              background-color: $blue_color;
              border: none;
            }
          }
        }

        .Box {
          width: 100%;
          margin-bottom: .6rem;

          label {
            @include label;
            color: $black_color;
          }

          input {
            width: 100%;
            height: 45.02px;
            box-sizing: border-box;
            border-radius: 2.6235px;
            background-color: $white_color !important;
            outline: none;
            border: 1px solid transparent;
            padding: .5rem 1rem;
            @include label;
            color: $black_color;
            font-size: 13px;
            //text-transform: capitalize;
            margin-top: .3rem;
            font-weight: normal;

            &::placeholder {
              @include inputText;
              color: $grey_color;
              font-size: 13px;
            }

            &.error {
              border-color: $error_color !important;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
          }

          span {
            @include placeholderText;
            color: $error_color;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Local_Shipment_Wrapper {
    width: 96%;
  }
}

@media (max-width: 400px) {
  .Local_Shipment_Wrapper {
    width: 96%;

    .Dynamic_Display_Wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    h3 {
     font-size: 18px !important;
     margin-bottom: 0 !important;
    }

    .Price_Param{
      font-size: 16px !important;
      line-height: normal;
    }

    .Wrap {
      flex-direction: column;
      gap: .5rem;

      .Left {
        width: 100%;
      }

      .Right {
        width: 100%;
        margin-bottom: 1rem;

        form {
          height: auto;
          padding-inline: .7rem;

          .Top {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
@import './mixin.scss';
@import './variables.scss';

.ContactWrapper{
    width: 100%;
    height: auto;
    p {
        font-family: 'Manjari';
    }
    .InfoSection {
        padding: 7.56rem 0 10.12rem 0;
        background: #FDF6F5;
        position: relative;
        width: 100%;
        @include flex;

        .InfoHero {
            color: #2563A3;
            text-align: center;
            font-family: Rockwell;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .InfoSub {
            color: #413D3D;
            text-align: center;
            font-family: Manjari;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 5.41rem;
        }

        .AddressContain {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 9.25rem;
            row-gap: 5.46rem;

            .AddressCard {
                width: 25.875rem;
                height: 15.9375rem; 
                border-radius: 0.9375rem;
                background: #FFFCFC;
                box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 2.63rem 1.5rem 2.94rem 2.06rem;

                p { 
                    text-align: center;
                    margin-bottom: 1.56rem;
                }

                .AddressState {
                    color: #000;
                    font-family: Manjari;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }

        .PhoneContain {
            @include flex;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 10px;
        }
    }

    .ContactForm {
        padding: 6rem 7.87rem 14.12rem 7.87rem;

        .InfoHero {
            color: #2563A3;
            text-align: center;
            font-family: Rockwell;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 2.19rem;
        }

        .InfoSub {
            color: #413D3D;
            text-align: center;
            font-family: Manjari;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 7.75rem;
        }

        .FormContain {
            height: 56.625rem; 
            border-radius: 1.5625rem;
            background: #EFF1F4;
            padding: 5.75rem 4.63rem 4.87rem 4.19rem;

            .BtnWrap {
                width: 33.625rem;
            }

            .FormGrid {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, minmax(0,1fr));
                margin-bottom: 3.19rem;
                gap: 4.35rem;
            }

            .TextAreaContain {
                width: 100%;
                margin-bottom: 3.19rem;
            }
        }

    }
}

@media (max-width:768px){
    .ContactWrapper{
        .InfoSection {
            padding: 3.56rem 1rem 5.12rem 1rem;
            background: #FDF6F5;
            position: relative;
            width: 100%;
            @include flex;
    
            .InfoHero {
                font-size: 1.5rem;
            }
    
            .InfoSub {
                font-size: 1rem;
                margin-bottom: 2.41rem;
            }
    
            .AddressContain {
                @include flex;
                flex-direction: column;
                gap: 3rem;
    
                .AddressCard {
                    width: 100%;
                    height: 12.9375rem; 
                    border-radius: 0.9375rem;
                    background: #FFFCFC;
                    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
                    padding: 2.63rem 1.5rem 2.94rem 2.06rem;
    
                    p { 
                        text-align: center;
                        font-size: .8rem;
                        margin-bottom: 1.56rem;
                    }
    
                    .AddressState {
                        color: #000;
                        font-family: Manjari;
                        font-size: 1.2rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }

            .PhoneContain {
                row-gap: 4px;
            }
        }
    
        .ContactForm {
            padding: 4rem 1rem 5.12rem 1rem;
    
            .InfoHero {
                font-size: 2rem;
            }
    
            .InfoSub {
                font-size: 1rem;
                margin-bottom: 2.41rem;
            }
    
            .FormContain {
                height: auto; 
                border-radius: 1.5625rem;
                background: #EFF1F4;
                padding: 5.75rem 1rem 4.87rem 1rem;

                .BtnWrap {
                    width: 100%;
                }
    
                .FormGrid {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(1, minmax(0,1fr));
                    margin-bottom: 2rem;
                    gap: 2rem;
                }
    
                .TextAreaContain {
                    width: 100%;
                    margin-bottom: 3.19rem;
                }
            }
    
        }
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                li{
                    &:nth-of-type(1){
                        margin-right:2rem;
                    }
                    &:nth-of-type(3){
                        width: 200px;
                        margin-right:1rem;
                    }
                    &:nth-of-type(2){
                        width: 300px;
                    }
                    .Box{
                        width:250px;
                    }
                }
            }
            .Footer_Bottom{
                width: 100%;
            }
        }
    }
}

@media (max-width:400px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                flex-direction: column;
                li{
                   width: 100%;
                //    &:last-child{
                    
                //     p{
                //         width: 100%;
                //         border: 1px solid red;
                //     }
                //    }
                    .Box{
                        width:100%;
                    }
                }
            }
            .Footer_Bottom{
                align-items: flex-start;
                flex-direction: column;
                .Left{
                    width: 100%;
                    margin-bottom: 1rem;
                }
                .Right{
                    width: 100%;
                }
            }
        }
    }
}
@import './mixin.scss';
@import './variables.scss';


.Payment_Method{
    width: 100%;
    height: auto;
    background-color: $tab_bg;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 1.5rem;
    h3{
        @include PackageNum;
        color: $grey_color;
    }
    .Box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:1rem 0rem;
        box-sizing: border-box;
        border-bottom: 1px solid $border_color;
        &:nth-of-type(1){
            border-top: 1px solid $border_color;
        }
        input[type="radio"]{
            appearance: none;
            -webkit-appearance: none;
            width: 24px;
            height: 24px;
            background-color:$radio_bg;
            border: 3px solid $tab_inactive_color;
            border-radius: 100%;
            &:checked{
                border-color: $blue_color;
                border-width:6px;
            }
        }
        .Detail{
            h4{
                @include CardTitle;
                color: $black_color;
            }
            h5{
                @include TabSubText;
                color: $grey_color;
            }
        }
    }
    .Balance{
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .Wrap{
            display: flex;
            align-items:center;
            // justify-content: flex-start;
            h2{
                @include DashbaoardWelcomeText;
                color: $blue_color;
              }
              h5{
                @include PackageNum;
                color: $blue_color;
              }
        }
        h6{
            font-weight: 500;
            font-size: 10px;
            line-height: 21px;
            color: $blue_color;
            &.Danger_Balance{
                color: $fade_error;
            }
        }
    }
    small{
        @include PackageSubText;
        color: $grey_color;
    }
    button{
        @include DangerBtn;
    }
}
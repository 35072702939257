@import './mixin.scss';
@import './variables.scss';


.Confirm{
    width: 100%;
    height: auto;
    background-color: $tab_bg;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 1.5rem;
    h2{
        @include PackageNum;
        color: $blue_color;
        margin-bottom:1rem;
    }
    .Box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $border_color;
        box-sizing: border-box;
        padding: 1rem 0rem;
        p{
            @include ConfirtText;
            color: $grey_color;
        }
        .Right{
            display: flex;
            align-items:flex-end;
            justify-content:flex-end;
            flex-direction: column;
            h4{
                @include ConfirtTitle;
                color: $black_color;
            }
            h6{
                @include ConfirtText;
                color: $grey_color;
            }
        }
    }
    .Bottom_Box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $border_color;
        box-sizing: border-box;
        padding: 1rem 0rem;
        .Left{
            @include PackageSubText;
            color: $grey_color;
        }
        .Right{
            display: flex;
            align-items:flex-end;
            justify-content: flex-start;
            flex-direction: column;
            h3{
                @include ConfirtTitle;
                color: $blue_color;
                margin-bottom: 0rem;
            }
            h5{
                @include ConfirtText;
                color: $grey_color;
            }
        }
    }
    button{
        @include DangerBtn;
    }
}

.Successfull{
    width: 100%;
    height:  479.14px;
    background-color: $tab_bg;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4{
        @include ConfirtTitle;
        color: $black_color;
        margin-bottom:1rem;
    }
    p{
        @include ConfirtText;
        color: $grey_color;
    }
    .Btn{
        @include DangerBtn;
        @include flex;
        width: 120px;
        margin-top: 2rem;
        cursor: pointer;
    }
}
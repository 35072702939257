@import './mixin.scss';
@import './variables.scss';

.Map_Section{
    @include setting;
    height: auto;
    // background-image: url("../Img/zeeMap.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: contain;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top:8rem;
    padding-bottom:5rem;
    h6{
        @include shipText;
        color: $fade_error;
    }
    h3{
        @include shipTitle;
        color: $black_color;
        margin-top: 1rem;
        margin-bottom: .5rem;
    }
    p{
        @include HeroText;
        color: $grey_color;
    }
}

@media (max-width:768px){
    .Map_Section{
        height:auto;
        padding-top: 3rem;
        margin-top: 2rem;
    }
}
@media (max-width:400px){
    .Map_Section{
        width: 95%;
        height:auto;
        padding-top: 3rem;
        margin-top: 2rem;
        margin-inline: auto;
        align-items: flex-start;
        h3,p{
            text-align: left;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@400;700&display=swap');
@font-face {
  font-family: Rockwell;
  src: url("./Assets/Rockwell-Font/Rockwell-Bold.ttf");
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p,ul{
  margin: 0px;
  padding: 0px;
}
a{
  text-decoration: none;
}

.SpinContain {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  width: 100%;
}


.shipment-slick .slick-track {
  margin-left: 0;
}

.shipment-slick .slick-slide {
  padding-right: 10px;
}

.accordion-button:focus {
  border: none;
}
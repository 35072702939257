@import './mixin.scss';
@import './variables.scss';

.General_Header{
@include setting;
height: 67px;
border-bottom: 1px solid $border_color;
padding:1rem 0rem;
.Inner_Container{
    width: 70%;
    height: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Right{
      ul{
        background-color: red;
      }
        a{
          @include LinkText;
          color: $black_color; 
          margin-inline: 1rem;
          &.active-link{
            color: $blue_color;
          } 
        }
        .Account_Btn{
          @include LinkText;
          color: $black_color; 
          cursor:pointer;
        }
    }
}

.Inner_Container_mobile {
  display: none;
}

}


@media (max-width:768px){
    .General_Header{
      width: 100%;
      overflow: hidden;
        .Inner_Container{
            width: 98%;
            display: none;
            .Right{
                a{
                display: none;
                }
                .Account_Btn{
                  display: none;
                }
            }
        }

        .pageLinks {
          color: #000000 !important;
        }

        .Inner_Container_mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1rem;

          .menuIcon {
            font-size: 24px;
          }

        }
        
        }
}
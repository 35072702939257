@import './mixin.scss';
@import './variables.scss';

.Shipments_Wrapper{
    width: 70%;
    margin-inline: auto;
    box-sizing: border-box;
    .Shipment_Top{
       margin-top: 2rem;
        h3{
            @include DashbaoardWelcomeText;
            color: $deep_dark;
            margin-bottom: 1rem;
        }
        .Top{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            box-sizing: border-box;
            h4{
                @include WalletTableHeader;
                color: $deep_dark;
            }
            .Select_Box{
                @include flex;
                justify-content: flex-end;
                gap: 1rem;
                select{
                    width: 180px;
                    height: 35px;
                    border-radius: 2.6235px;
                    border: 1px solid $tab_inactive_color;
                    box-sizing: border-box;
                    // padding:1rem .3rem;
                    text-transform: capitalize;
                    outline: none;
                    @include inputText;
                    color: $select_text_color;
                }
                .Search_Box{
                    background-color: $blue_color;
                    @include flex;
                    width: 34.99px;
                    height: 35px;
                    border-radius: 2px;
                }   
            }
        }
    }
    .Shipment_Table_Wrapper{
        h4{
            @include WalletTableHeader;
            color: $deep_dark;
        }
        table{
            width: 100%;
            border-collapse: collapse;
            margin-top: 1.5rem;
            thead{
                width: 100%;
                th{
                    width: calc(100%/4.5);
                    @include TabSubText;
                    color: $grey_color;
                    padding: 1rem 0rem;
                    border-bottom: 1px solid $wallet_border;
                }
            }
            tbody{
                width: 100%;
                tr{
                    td{
                        border-bottom:1px solid $wallet_border;
                        .No_Data{
                            width: 100%;
                            text-align: center;
                            padding:4rem 0rem;
                            h5{
                                @include WalletTableBodyText;
                                color: $deep_dark; 
                            }
                        } 
                        .Box{
                            width: 100%;
                            height:65px;
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            flex-direction: column;
                            margin:.5rem 0rem;
                            h5{
                                @include WalletTableBodyText;
                                color: $deep_dark;
                            }
                            p{
                              @include TabSubText;
                              color: $grey_color;  
                            }
                            .Btn{
                                width: 100px;
                                height: 26.71px;
                                border-radius: 1.44093px;
                                @include WalletBtnText;
                                @include flex;
                                &.success{
                                    background-color:$wallet_success_btn_bg;
                                    color: $wallet_success_btn;
                                }
                                &.cancel{
                                    background-color:$light_cancel;
                                    color:$deep_cancel;
                                }
                                &.active{
                                    background-color:$light_active_btn;
                                    color:$deep_active_btn;
                                }
                                &.pending{
                                    background-color:$light_orange;
                                    color:$deep_orange;
                                }
                                &.pending-pickup{
                                    background-color:$purple_bg;
                                    color:$purple_color;
                                }
                            }
                        } 
                    }
                }
            }
        }
    }
    .Shimpent_Navigate{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: .5rem;
        margin:1.5rem 0rem;
        margin-bottom: 5rem;
        .Btn{
            @include flex;
            width: 43.55px;
            height: 43.55px;
            border-radius: 100%;
            background-color: $arrow_btn_color;
        }
    }
}

.ShipmentInfo {
    min-width: 700px;
    max-height: 400px;
    overflow-y: auto;

    .ShipmentSection {
        padding: 1rem 0;

        .ShipmentLabel {
            font-weight: 600;
            font-size: 16px;
        }

        .InfoLabel {
            font-weight: 500;
        }

        .ShipmentInfo {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            div {
                margin-bottom: .8rem;
            }

            .ShipRight {
                text-align: right;
            }

            .BtnShip{
                padding: 0 .5rem;
                height: 26.71px;
                font-size: 10px !important;
                border-radius: 1.44093px;
                text-transform: uppercase;
                flex-wrap: nowrap;
                cursor: pointer;
                font-size: 14px !important;
                &.success{
                    background-color:$wallet_success_btn_bg;
                    color: $wallet_success_btn;
                }
                &.cancel{
                    background-color:$light_cancel;
                    color:$deep_cancel;
                }
                &.active{
                    background-color:$light_active_btn;
                    color:$deep_active_btn;
                }
                &.pending{
                    background-color:$light_orange;
                    color:$deep_orange;
                }
            }
        }
    }

    .SectionDivide {
        border-bottom: 1px solid $wallet_border;
    }

    p {
        margin: 0;
        // color: ;
        font-size: 14px;
    }
}

@media (max-width:768px){
    .Shipments_Wrapper{
        width:95%;
    }
}

@media (max-width:400px){
    .Shipments_Wrapper{
        width:95%;

        .Shipment_Top{
            .Top{
                
                .Select_Box{
                    display: flex;
                    flex-direction: column !important;
                    gap: 1rem;

                    select {
                        width: 100% !important;
                    }
                }
            }
        }

        .Shipment_Table_Wrapper{
            table{
                width: 100%;
                border-collapse: collapse;
                margin-top: 1.5rem;
                thead{
                    width: 100%;
                    th{
                        width: calc(100%/4.5);
                        @include TabSubText;
                        color: $grey_color;
                        padding: 1rem 0rem;
                        border-bottom: 1px solid $wallet_border;
                    }
                }
                tbody{
                    width: 100%;
                    tr{
                        td{
                            border-bottom:1px solid $wallet_border;
                            .No_Data{
                                width: 100%;
                                text-align: center;
                                padding:4rem 0rem;
                                h5{
                                    @include WalletTableBodyText;
                                    color: $deep_dark; 
                                }
                            } 
                            .Box{
                                width: 100%;
                                height:auto;
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                flex-direction: column;
                                margin:.5rem 0rem;
                                h5{
                                    @include WalletTableBodyText;
                                    color: $deep_dark;
                                }
                                p{
                                  @include TabSubText;
                                  color: $grey_color;  
                                }
                                .Btn{
                                    width: 100px;
                                    height: 26.71px;
                                    border-radius: 1.44093px;
                                    @include WalletBtnText;
                                    @include flex;
                                    &.success{
                                        background-color:$wallet_success_btn_bg;
                                        color: $wallet_success_btn;
                                    }
                                    &.cancel{
                                        background-color:$light_cancel;
                                        color:$deep_cancel;
                                    }
                                    &.active{
                                        background-color:$light_active_btn;
                                        color:$deep_active_btn;
                                    }
                                    &.pending{
                                        background-color:$light_orange;
                                        color:$deep_orange;
                                    }
                                }
                            } 
                        }
                    }
                }
            }
        }
    }

    .ShipmentInfo {
        width: 95vw;
        min-width: auto;
        // max-height: 400px;
        // overflow-y: auto;
    
        .ShipmentSection {
            padding: 1rem 0;
    
            .ShipmentLabel {
                font-weight: 600;
                font-size: 14px;
            }
        }
    
        p {
            margin: 0;
            // color: $light_gray;
            font-size: 12px;
        }
    }
}
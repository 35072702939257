@import './mixin.scss';
@import './variables.scss';


.Modal_Wrapper{
    width:540px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 2rem;
    .close_btn{
        position: absolute;
        right:1rem;
        top:1rem;
    }
    .Tab_Wrapper{
        width: 100%;
        // margin-top: 2rem;
        display: flex;
        align-items: center;
        gap:.3rem;
        .Tab{
            width: 160px;
            box-sizing: border-box;
            height:60px;
            border-radius: 3px;
            background-color:$powder_blue;
            color: $deep_dark;
            @include WalletTabText;
            @include flex;
            &.active_tab{
                background-color: $grey_color;
                color:$inactive_tab_text;
            }
        }
    }
    .Details_Wrapper{
        width:100%;
        height: auto;
        border-radius: 3px;
        background-color:$powder_blue;
        box-sizing: border-box;
        padding:3.5rem 0rem;
        @include flex;
        flex-direction: column;
        &.Amount{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-inline:3rem;
            .Btn{
                @include DangerBtn;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            label {
                @include PackageText;
                color: $black_color;
                &.error{
                    color: $error_color;
                }
              }
    
              input {
                width: 100%;
                height: 45.02px;
                box-sizing: border-box;
                border-radius: 2.6235px;
                background-color: $white_color !important;
                outline: none;
                border: 1px solid transparent;
                padding: .5rem 1rem;
                @include label;
                color: $black_color;
                font-size: 13px;
                //text-transform: capitalize;
                margin:1rem 0rem;
                font-weight: normal;
    
                &::placeholder {
                  @include inputText;
                  color: $grey_color;
                  font-size: 13px;
                }
            }
        }
        .Top{
            width: 80%;
            margin-inline: auto;
        }
        .Bottom{
            width: 80%;
            margin-inline: auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 2rem;
        }
        .Box{
            p{
                @include WalletTransferText;
                color: $grey_color;
            }
            h5{
                // @include WalletTabHeader;

            }
        }
    }
}

.Wallet_Top{
width: 70%;
margin-inline: auto;
box-sizing: border-box;
.Top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    h2{
        @include DashbaoardWelcomeText;
        color: $deep_dark;
    }
    .Button_Box{
        display: flex;
        align-items: center;
        gap:2rem;
        .Btn{
            @include  walletBtnText;
            width: 191.34px;
            height: 50px;
            box-sizing: border-box;
            border: 1px solid $inactive_tab_text;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            color: $deep_dark;
            &.Danger{
                border: none;
                background-color:$fade_error;
                color:$white_color;
            }
        }
    }
}

.PayDescr {
    margin-bottom: 2rem;
}

.Wallet_Tab{
display: flex;
align-items: center;
gap: 1rem;
margin-top:3.5rem;
margin-bottom: 2rem;
.Box{
    width: 100%;
    height: 80.12px;
    border-radius: 2px;
    background-color: $powder_blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-inline:1rem;
    h3{
        @include WalletTabHeader;
        color: $deep_dark;
    }
    h2{
        @include WalletTabHeader;
        color: $deep_dark;
    }
    .Left{
        margin-left:1.5rem;
    }
    .Right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap:.5rem;
        .Svg_Box{
            width: 20.88px;
            height: 20.33px;
            border-radius: 1px;
           @include flex;
           background-color: $blue_color;
           svg{
            color: $blue_color;
           }
        }
        .Box{
            width: 100.57px;
            height: 20.33px;
            border-radius: 1px;
            @include flex;
            @include  WalletBoxSubText;
            background-color: $white_color;
            color: $grey_color;
        }
    }
    &:nth-of-type(1){
        background-color:$blue_color;
        .Left{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            h4{
                @include  WalletBoxSubText;
                color:  $white_color;
            }
            .Wrap{
                display: flex;
                align-items: flex-end;
                h2{
                    color:  $white_color;
                  }
                  h6{
                      @include  WalletBoxSubText;
                      color:  $white_color;
                    }
            }
        }
        .Svg_Box{
            width: 20.88px;
            height: 20.33px;
            border-radius: 1px;
           @include flex;
           background-color: $white_color;
        } 
    }
}
}
.Table_Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 1.5rem;
    border-bottom:1px solid $wallet_border;
    &.activate_fade{
        opacity:.1;
    }
    h3{
        @include WalletTableHeader;
        color: $deep_dark;
    }
    .Right{
        @include flex;
        gap: 1rem;
        select{
            width: 180px;
            height: 35px;
            border-radius: 2.6235px;
            border: 1px solid $tab_inactive_color;
            box-sizing: border-box;
            // padding:1rem .3rem;
            text-transform: capitalize;
            outline: none;
            @include inputText;
            color: $select_text_color;
        }
        .Search_Box{
            background-color: $blue_color;
            @include flex;
            width: 34.99px;
            height: 35px;
            border-radius: 2px;
        }
    }
}
.Empty_Wallet{
    @include flex;
    box-sizing: border-box;
    flex-direction: column;
    padding:6rem  0rem;
    h3{
        @include WalletEmptyHead;
        color: $deep_dark;
    }
    p{
        @include WalletEmptyText;
        color: $grey_color;
    }
}
.Transaction{
    margin-bottom: 6rem;
    table{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        border-collapse: collapse;
        thead{
            width: 100%;
            th{
                @include TabSubText;
                color: $grey_color;
                width: calc(100%/3);
                box-sizing: border-box;
                padding:1.2rem .5rem;
            }
        }
        tbody{
            width: 100%;
            tr{
                width: 100%;
                td{
                    width: calc(100%/3);
                    box-sizing: border-box;
                    padding:0rem .5rem;
                    .Box{
                        @include WalletTableBodyText;
                        text-transform: capitalize;
                        color: $deep_dark;
                        width: 100%;
                        height:50px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        .Btn{
                            width: 100px;
                            height: 26.71px;
                            border-radius:1.44093px;
                            cursor: pointer;
                            color: $wallet_success_btn;
                            background-color: $wallet_success_btn_bg;
                            @include flex;
                            @include  WalletBtnText;
                            &.success{
                                background-color:$wallet_success_btn_bg;
                                color: $wallet_success_btn;
                            }
                            &.cancel{
                                background-color:$light_cancel;
                                color:$deep_cancel;
                            }
                            &.active{
                                background-color:$light_active_btn;
                                color:$deep_active_btn;
                            }
                            &.pending{
                                background-color:$light_orange;
                                color:$deep_orange;
                            }
                        }
                    }
                }
            }
        }
    }
}
}

@media (max-width:768px){
    .Wallet_Top{
        width:90%;
    }
}

@media (max-width:400px){
    .Wallet_Top{
        width:95%;
        .Top{
            flex-direction: column;
            align-items: flex-start;
            .Button_Box{
                width: 100%;
                gap:2rem;
                margin-top: 1rem;
                .Btn{
                    width: 100%;
                }
            }
        }
        .Wallet_Tab{
            flex-direction: column;
            margin-top:1rem;
            }
            .Table_Header{
                flex-direction: column;
                align-items: flex-start;
                .Right{
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    select{
                    width: 100%;
                    }
                }
            }
            .Transaction{
                margin-bottom: 6rem;
                table{
                    thead{
                        width: 100%;
                        th{
                            width:100%;
                            display: block;
                        }
                    }
                    tbody{
                        width: 100%;
                        tr{
                           &:nth-of-type(even){
                            background-color:$tab_active_color;
                           }
                            td{
                                width:100%;
                               display: block;
                            }
                        }
                    }
                }
            }
        }
        .Modal_Wrapper{
            width:auto;
            .Details_Wrapper{
                &.Amount{
                    padding-inline:2rem;
                }
            }
        }
}
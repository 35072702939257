@import './mixin.scss';
@import './variables.scss';

.Footer_Wrapper{
    width: 100%;
    height: auto;
    background-color: $blue_color;
    .Inner_Container{
        width: 70%;
        margin-inline: auto;
        padding-top:7rem;
        padding-bottom: 3rem;
        overflow: hidden;
        ul{
            list-style: none;
            padding:0px;
            margin: 0px;
            display: flex;
            align-items: flex-start;
           gap: 2rem;
            li{
                &:nth-of-type(1){
                    margin-right: 4rem;
                }
                &:nth-of-type(3){
                    width: 200px;
                    margin-right: 2rem;
                }
                &:nth-of-type(2){
                    width: 230px;
                }
                p{
                    @include ConfirtText;
                    color: $white_color;
                    a{
                        color: $white_color;
                    }
                  }
                  h4{
                    @include footerTitle;
                    color: $white_color;
                    margin-bottom: .5rem;
                    
                  }
                .Box{
                    width: 300px;
                    p{
                        margin-bottom:2rem;
                    }
                }
            }
        }
        .Footer_Bottom{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            padding-top: 1rem;
            margin-top: 3rem;
             border-top:1px solid rgba(255, 255, 255, 0.25);;
            .Left{
                @include footerBottomText;
                color: $white_color;
                width:50%;
            }
            .Right{
                display: flex;
                align-items: center;
                justify-content: flex-start;
            img{
                margin-right: .5rem;
            }
            }
        }
    }
}

@media (max-width:768px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                li{
                    &:nth-of-type(1){
                        margin-right:2rem;
                    }
                    &:nth-of-type(3){
                        width: 200px;
                        margin-right:1rem;
                    }
                    &:nth-of-type(2){
                        width: 300px;
                    }
                    .Box{
                        width:250px;
                    }
                }
            }
            .Footer_Bottom{
                width: 100%;
            }
        }
    }
}

@media (max-width:400px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                flex-direction: column;
                li{
                   width: 100%;
                //    &:last-child{
                    
                //     p{
                //         width: 100%;
                //         border: 1px solid red;
                //     }
                //    }
                    .Box{
                        width:100%;
                    }
                }
            }
            .Footer_Bottom{
                align-items: flex-start;
                flex-direction: column;
                .Left{
                    width: 100%;
                    margin-bottom: 1rem;
                }
                .Right{
                    width: 100%;
                }
            }
        }
    }
}
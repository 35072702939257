@import './mixin.scss';
@import './variables.scss';

.Setting_Wrapper{
    width: 70%;
    margin-inline: auto;
    box-sizing: border-box;
    margin-top: 6rem;
    margin-bottom:10rem;
    h3{
        @include DashbaoardWelcomeText;
        color: $deep_dark;
        margin-bottom: 1rem;
    }
    .Layout{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top:4rem;
        .Left{
            width: 25%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            .Box{
                width: 150px;
                h4{
                  @include fileLabel;
                  color: $deep_dark; 
                  margin-bottom: .6rem; 
                }
                Label{
                    .Btn{
                        width: 150px;
                        height:40px;
                        border-radius: 3px;
                        @include flex;
                        color:$white_color;
                        background-color: $wallet_success_btn;
                        text-align: center;
                        margin-top: .5rem;
                        cursor: pointer;
                        @include BtnText;
                    }
                    .Label_Box{
                        width: 161.2px;
                        height: 159.9px;
                        border: 1px dashed $inactive_tab_text;
                        border-radius: 3px;
                        @include flex;
                        flex-direction: column;
                        span{
                            @include fileText;
                            color: $inactive_tab_text;
                        }
                    }
                }
                img{
                    width:150px;
                    aspect-ratio: 1;
                    border-radius: 5px;
                }
            }
        }
        .Right{
            width: 68%;
            .Detail_Wrapper{
                width: 100%;
                .Box{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $border_color;
                    padding:1rem 0rem;
                    &:nth-of-type(1){
                        padding-top: 0rem;
                        .Left{
                            h4{
                                @include SettingBasicText;
                            }
                        }
                    }
                    .Left{
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: row;
                        gap: .5rem;
                        h4{
                            @include SettingTitleText;
                            color: $deep_dark;
                        }
                        .Btn{
                            width: 89.94px;
                            height: 25.74px;
                            border-radius: 17.6476px;
                            background-color:$fade_error;
                            color: $white_color;
                            @include  SettingDangerBtnText;
                            @include flex;
                            cursor: pointer;
                        }
                    }
                    .Right{
                        display: flex;
                        align-items:flex-end;
                        justify-content: center;
                        flex-direction: column;
                        p{
                            @include TabSubText;
                            color: $grey_color;
                        }
                        .Btn{
                            width: 80.69px;
                            height: 25.74px;
                            border-radius: 17.6476px;
                            background-color: $powder_blue;
                            color: $deep_dark;
                            @include PackageNum;
                            @include flex;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.Settin_Modal_Wrapper{
    width:400px;
    height: 100%;
    box-sizing: border-box;
    padding:2.5rem 2.5rem;
    .Details_Wrapper{
        width:100%;
        height: auto;
        border-radius: 3px;
        box-sizing: border-box;
        padding:0rem;
        @include flex;
        flex-direction: column;
        &.Amount{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-inline:0rem;
            .Btn{
                @include DangerBtn;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            label {
                @include SettingChangePWD;
                color: $black_color;
              }
    
              input {
                width: 100%;
                height: 45.02px;
                box-sizing: border-box;
                border-radius: 2.6235px;
                background-color: $light_blue;
                outline: none;
                border: 1px solid transparent;
                padding: .5rem 1rem;
                @include label;
                color: $black_color;
                font-size: 13px;
                //text-transform: capitalize;
                margin:1rem 0rem;
                font-weight: normal;
    
                &::placeholder {
                  @include inputText;
                  color: $grey_color;
                  font-size: 13px;
                }
            }
        }
        .Top{
            width: 80%;
            margin-inline: auto;
        }
        .Bottom{
            width: 80%;
            margin-inline: auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-top: 2rem;
        }
        .Box{
            p{
                @include WalletTransferText;
                color: $grey_color;
            }
            h5{
                // @include WalletTabHeader;

            }
        }
    } 
}

@media (max-width:768px){
    .Setting_Wrapper{
        width:95%;
        .Layout{
            .Left{
                width: 25%;
                .Box{
                    width: 150px;
                    h4{
                      @include fileLabel;
                      color: $deep_dark; 
                      margin-bottom: .6rem; 
                    }
                    Label{
                        .Btn{
                            width: 150px;
                            height:40px;
                            border-radius: 3px;
                            @include flex;
                            color:$white_color;
                            background-color: $wallet_success_btn;
                            text-align: center;
                            margin-top: .5rem;
                            cursor: pointer;
                            @include BtnText;
                        }
                        .Label_Box{
                            width: 161.2px;
                            height: 159.9px;
                            border: 1px dashed $inactive_tab_text;
                            border-radius: 3px;
                            @include flex;
                            flex-direction: column;
                            span{
                                @include fileText;
                                color: $inactive_tab_text;
                            }
                        }
                    }
                    img{
                        width:150px;
                        aspect-ratio: 1;
                        border-radius: 5px;
                    }
                }
            }
            .Right{
                width: 68%;
            }
        }
    }  
}

@media (max-width:400px){
    .Setting_Wrapper{
        width:95%;
        .Layout{
            flex-direction: column;
            .Left{
                width: 100%;
                align-items: flex-start;
            }
            .Right{
                width:100%;
                margin-top:2rem;
            }
        }
    }  
}
@import './mixin.scss';
@import './variables.scss';

.suggestions-field {
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  display: none;
  position: absolute;
  z-index: 10;
}

.suggestions-field-visible {
  display: block;
  width: 70%;
}

.btnTab {
  background-color: transparent !important;
  color: $blue_color !important;
  border: 1px solid $blue_color !important;
}

.single-suggestion{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: #74788d!important;

  &:hover{
    background-color: #ddd;
  }
}

.Price_Card{
  width: 100%;
  background-color:$white_color;
  box-shadow: 17.6775px 17.6775px 35.355px -10.6065px $hero_shadow;
  border-radius: 9.05154px;
  height: auto;
  padding:1.2rem;
  border: 1px solid $input_border;
  margin-bottom:.5rem;
  &.active{
      background-color:$sidebar_color;
  }
  .Logo {
      // background-image: url('../img/Ella.png');
     img {
      width: 70px;
      height: 70px;
     }
  }
  // &:nth-of-type(1){
  //     .Logo{
  //         background-image: url('../img/shDHL.jpeg');
  //     }
  //     .Right{
  //         h2{
  //             @include TabTitle;
  //             color: $secondary_color;
  //         }
  //     }
  // }
  // &:nth-of-type(2){
  //     .Logo{
  //         background-image: url('../img/UP.jpg');
  //     }
  // }
  // &:nth-of-type(3){
  //     .Logo{
  //         background-image: url('../img/FedX.png');
  //     }
  // }
  // &:nth-of-type(4){
  //     .Logo{
  //         background-image: url('../img/FedX.png');
  //     }
  // }
  .Wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Logo{
          width: 70px;
          height: 70px;
          @include LogoBg;
          
        }
      .Left{
          font-weight: 600;
          margin: 0rem;
          font-size: 14px;
          &.Name{
              font-size:20px;
          }
      }
      .Right{
          font-size: 13px;
          color: $grey_color;
      }
  }
}

.PrevBtn {
  width: 100%;
  height: 50px;
  margin-top: 0.5rem;
  background-color: $blue_color;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-top: 2rem;
  cursor: pointer;
}

@media (max-width: 400px) {
  .Price_Card {
    width: 80vw;
    padding: 1rem .5rem;
  }
}
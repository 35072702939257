@import './mixin.scss';
@import './variables.scss';

.BenefitWrap {
    .Title {
        color: #2563A3;
        font-size: 2rem;
        font-style: normal;
        font-family: Rockwell;
        font-weight: 700;
        line-height: normal; 
        text-align: center;
        padding-top: 90px;
    }
    .BenefitsSection{
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        margin-left: auto;
        padding: 20px 120px 46px 100px;
        @include flex;
        p {
            font-family: 'Manjari';
        }
        
        .GridSection {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 142px;
            row-gap: 60px;
    
            .BenefitIcon {
                margin-bottom: 25px;
            }
    
    
            .BenefitName {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal; 
                margin-bottom: 25px;
            }
            .description {
                margin-bottom: 25px;
                color: #413D3D;
                text-align: justify;
                font-size: .8rem;
            }
    
            .BenefitLink {
                color: #EC525A;
            }
        }
        
    }
}

@media (max-width:768px){
    .BenefitWrap {
        .Title {
            font-size: 1.5rem;
            padding-top: 20px;
        }
        .BenefitsSection{
            width:100%;
            padding: 40px 20px;
            margin-inline: auto;
            .GridSection {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                column-gap: 40px;
            }
        }
    }
}
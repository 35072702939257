@import './mixin.scss';
@import './variables.scss';

.Footer_Wrapper{
    width: 100%;
    height: auto;
    background-color: #343434CC;
    p {
        font-family: 'Manjari';
    }
    .Inner_Container{
        width: 100%;
        padding: 3.06rem 6.5rem 1.81rem 7.52rem;
        margin-inline: auto;
        overflow: hidden;
        @include flex;
        align-items: flex-start;
        justify-content: space-between;
        
        .Left {
            .Apps {
                display: flex;
                gap: 2.25rem;
                margin-bottom: 5.06rem;
            }
            .Copyright {
                width: 33.06875rem;

                p {
                    color: #FFFAFA;
                    font-size: .8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }
        .Right {
            .BottomLinks {
                list-style: none;
                display: flex;
                column-gap: 2rem;
                row-gap: .5rem;
                margin-bottom: 6.56rem;
                justify-content: flex-end;

                li {
                    width: fit-content;
                }

                a {
                    color: #FFFAFA;
                    font-family: Manjari;
                    font-size: .8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .FooterSocials {
                display: flex;
                gap: 1.63rem;
                justify-content: flex-end;

                svg {
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width:768px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            padding: 3.06rem 1rem 1.81rem 1rem;

            ul{
               gap:1rem;
                li{
                    &:nth-of-type(1){
                        margin-right:2rem;
                    }
                    &:nth-of-type(3){
                        width: 200px;
                        margin-right:1rem;
                    }
                    &:nth-of-type(2){
                        width: 300px;
                    }
                    .Box{
                        width:250px;
                    }
                }
            }
            .Footer_Bottom{
                width: 100%;
            }

            .Right {
                .BottomLinks {
                    margin-bottom: 1rem;
                }
                .FooterSocials {
                    justify-content: flex-start;
                }
            }
        }
    }
}

@media (max-width:400px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            flex-direction: column;

            row-gap: 2rem;
            ul{
               gap:1rem;
                flex-direction: column;
                li{
                   width: 100%;
                //    &:last-child{
                    
                //     p{
                //         width: 100%;
                //         border: 1px solid red;
                //     }
                //    }
                    .Box{
                        width:100%;
                    }
                }
            }
            .Footer_Bottom{
                align-items: flex-start;
                flex-direction: column;
                .Left{
                    width: 100%;
                    margin-bottom: 1rem;
                }
                .Right{
                    width: 100%;
                }
            }
        }
    }
}
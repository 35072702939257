@import './mixin.scss';
@import './variables.scss';


.Questions{
    width: 100%;
    box-sizing: border-box;
    background-color: $powder_blue;
    padding:5rem  0rem;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3{
        @include shipTitle;
        color: $black_color;
        margin:.6rem 0rem;;
    }
    p{
        @include shipCardTitle;
        color: $grey_color;
    }
    .Question_Wrap{
        margin-top:3.5rem;
        width: 70%;
        margin-inline: auto;
        .Wrap{
            width: 100%;
            height: auto;
            .Question{
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding-inline: 1rem;
                height:50px;
                border-radius: 3px;
                // border:1px solid $grey_color;
                backdrop-filter: blur(100px);
                backface-visibility: hidden;
                h4{
                    @include questionText;
                    color: $black_color;
                }
                img{
                    cursor: pointer;
                }
                }
                .Q_DropDown{
                    width: 97%;
                    margin-inline: auto;
                    box-sizing: border-box;
                    height:0px;
                    background-color: $blue_color;
                    overflow: hidden;
                    text-align:left;
                    color: $white_color;
                    transition: all .3s ease-in-out;
                    padding-inline:.5rem;
                    &.active{
                        height: 60px;
                        margin-bottom: 1rem;
                    }
                }
        }
    }
}

@media (max-width:400px){
    .Questions{
        h3,p{
            text-align: center
        }
        .Question_Wrap{
           width:95%;
           .Wrap{
            .Q_DropDown{
                &.active{
                    height:120px;
                }
            }
           }
        }
    }
}
@import './mixin.scss';
@import './variables.scss';

.Testimonial_Wrapper{
width: 100%;
height: auto;
padding:7rem 0rem;
background-color: $blue_color;
@include flex;
gap:5rem;
.Left{
    h3{
        @include delivery_man_title;
        color: $white_color;
    }
    p{
        @include CurveEdgeCardText;
        color: $hero_text_color;
    }
    .Btn{
        @include flex;
        @include BtnText;
        border-radius: 3px;
        cursor: pointer;
        &.White{
            width: 130.98px;
            height: 35px;
            color: $deep_dark;
            background-color: $white_color;
            margin-bottom: 1rem;
        }
        &.Danger{
            width: 166.6px;
            height: 35px;
            background-color: $deep_cancel;
            color: $white_color;
            margin-top: 1.5rem;
        }
    }
}
.Right{
    .Card{
        width: 600px;
        height: 300px;
        background-color: $white_color;
        border-radius: 5px;
        border-top-left-radius:100px;
        padding:3rem;
        .Card_Details{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2rem;
            transition: all .4s ease-in-out;
            .Right{
                width: 80%;
                // background-color: red;
                .Text_Box{
                    width: 100%;
                    @include HeroText;
                    color: $grey_color;
                }
                h5{
                    @include CurveCardDetailTitel;
                    color: $deep_dark;
                    margin-top: 2rem;
                    margin-bottom:.5rem;
                }
                h6{
                    @include CurveCardDetailSubText;
                    color: $blue_color;
                }
            }
            
            img{
                border-radius: 100%;
                width: 80px;
                height:80px;
            }
        }
        .Botton_Nav{
            // border: 1px solid red;
            width: 79%;
            margin-left: auto;
            margin-top:.4rem;
            display: flex;
            align-items: center;
            gap: .5rem;
            .Dot{
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background-color: $Dot_color;
                transition: width .4s ease-in-out,height .4s ease-in-out;
                &.active{
                    width: 20.47px;
                    height: 20.47px;
                    background-color: $blue_color;
                }
            }
            svg{
                color: $blue_color;
                cursor: pointer;
            }
        }
    }
}
}

@media (max-width:768px){
    .Testimonial_Wrapper{
        overflow: hidden;
        gap:0rem;
        padding-inline: 1rem;
        .Left{
            width:40%;
        }
        .Right{
            width:60%;
            .Card{
                width: 100%;
                height:430px;
                padding:2rem;
                .Card_Details{
                    flex-direction: column;
                    .Right{
                        width: 100%;
                       .Text_Box{
                        width: 100%;
                       }
                    }
                }
                .Botton_Nav{
                    width:100%;
                }
            }
        }
        }
}

@media (max-width:400px){
    .Testimonial_Wrapper{
    flex-direction: column;
    gap:4rem;
    padding-inline: 1rem;
    .Left{
        width:100%;
        margin-top:0rem;
    }
    .Right{
        width:100%;
        .Card{
            width: 100%;
            height:450px;
            padding:2rem;
            .Card_Details{
                flex-direction: column;
                .Right{
                    width: 100%;
                    .Text_Box{
                    width: 100%;
                    }
                }
            }
            .Botton_Nav{
                width:100%;
            }
        }
    }
    }
}
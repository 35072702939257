@import './mixin.scss';
@import './variables.scss';

.TrackWrapper{
    width: 100%;
    height: auto;
    p {
        font-family: 'Manjari';
    }
    .TrackSection {
        padding: 6.56rem 0 8.12rem 0;
        background: #FDF6F5;
        position: relative;
        width: 100%;
        @include flex;

        .InfoHero {
            color: #2563A3;
            text-align: center;
            font-family: Rockwell;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .InfoSub {
            color: #413D3D;
            text-align: center;
            font-family: Manjari;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 5.41rem;
        }

        .TrackBtnContain {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 5rem
        }

        .AddressContain {
            @include flex;
            gap: 9.25rem;

            .AddressCard {
                width: 25.875rem;
                height: 15.9375rem; 
                border-radius: 0.9375rem;
                background: #FFFCFC;
                box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
                padding: 2.63rem 1.5rem 2.94rem 2.06rem;

                p { 
                    text-align: center;
                    margin-bottom: 1.56rem;
                }

                .AddressState {
                    color: #000;
                    font-family: Manjari;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }

    .ContactForm {
        padding: 6rem 7.87rem 22.12rem 7.87rem;

        .InfoHero {
            color: #2563A3;
            text-align: center;
            font-family: Rockwell;
            font-size: 3.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 2.19rem;
        }

        .InfoSub {
            color: #413D3D;
            text-align: center;
            font-family: Manjari;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 7.75rem;
        }

        .FormContain {
            height: 56.625rem; 
            border-radius: 1.5625rem;
            background: #EFF1F4;
            padding: 5.75rem 4.63rem 4.87rem 4.19rem;

            .FormGrid {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, minmax(0,1fr));
                margin-bottom: 3.19rem;
                gap: 4.35rem;
            }

            .TextAreaContain {
                width: 100%;
                margin-bottom: 3.19rem;
            }
        }

    }

    .TrackInfo {
        padding: 4.56rem 9.56rem 6.2rem 11.06rem;

        .ShipNumber {
            @include flex;
            justify-content: flex-start;
            gap: .4rem;
            margin-bottom: 1.56rem;

            .ShipCode {
                font-weight: 700;
            }
        }

        .TrackMessage {
            height: 20vh;
            @include flex;
        }

        .TrackEvent {
            padding-left: 1rem;

            .EventRow {
                @include flex;
                justify-content: space-between;
                padding-bottom: 3rem;

                .Col1 {
                    @include flex;
                    gap: 5.19rem;

                    .Checked {
                        position: relative;

                        .CheckedEvent{
                            width: 43px;
                            height: 43px;
                            border-radius: 100%;
                            background-color: #2F7B23;
                            @include flex;

                            svg {
                                color: #fff;
                                font-size: 1.5rem;
                            }
                        }

                        .UnCheckedEvent {
                            width: 43px;
                            height: 43px;
                            border-radius: 100%;
                            background-color: #9FA49E;
                            @include flex;

                            svg {
                                color: #fff;
                                font-size: 1.5rem;
                            }
                        }

                        .CheckedLine {
                            position: absolute;
                            width: 0;
                            height: 3rem;
                            border-left: 1px solid #2F7B23;
                            left: 21.5px;
                        }

                        .UnCheckedLine {
                            position: absolute;
                            width: 0;
                            height: 3rem;
                            border-left: 1px dashed #000;
                            left: 21.5px;
                        }
                    }
                }
            }
        }


    }
}

@media (max-width:768px){
    .TrackWrapper{
        .TrackSection {
            padding: 7.56rem 1rem 9.12rem 1rem;
            background: #FDF6F5;
            position: relative;
            width: 100%;
            @include flex;
    
            .InfoHero {
                font-size: 1.5rem;
            }
    
            .InfoSub {
                font-size: 1rem;
            }
    
            .TrackBtnContain {
                display: grid;
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: .5rem;
                padding: 0 1rem;
            }
    
            
        }
    
    
        .TrackInfo {
            padding: 4.56rem 1rem 6.2rem 1rem;
    
            .ShipNumber {
                @include flex;
                justify-content: flex-start;
                gap: .4rem;
                margin-bottom: 1.56rem;
    
                .ShipCode {
                    font-weight: 700;
                }
            }
    
            .TrackMessage {
                height: 20vh;
                @include flex;
            }
    
            .TrackEvent {
                padding-left: 1rem;
    
                .EventRow {
                    @include flex;
                    justify-content: space-between;
                    padding-bottom: 3rem;
    
                    .Col1 {
                        @include flex;
                        gap: .5rem;
    
                        .Checked {
                            position: relative;
    
                            .CheckedEvent{
                                width: 28px;
                                height: 28px;
                                border-radius: 100%;
                                background-color: #2F7B23;
                                @include flex;
    
                                svg {
                                    color: #fff;
                                    font-size: 1rem;
                                }
                            }
    
                            .UnCheckedEvent {
                                width: 28px;
                                height: 28px;
                                border-radius: 100%;
                                background-color: #9FA49E;
                                @include flex;
    
                                svg {
                                    color: #fff;
                                    font-size: 1rem;
                                }
                            }
    
                            .CheckedLine {
                                position: absolute;
                                width: 0;
                                height: 3rem;
                                border-left: 1px solid #2F7B23;
                                left: 14px;
                            }
    
                            .UnCheckedLine {
                                position: absolute;
                                width: 0;
                                height: 3rem;
                                border-left: 1px dashed #000;
                                left: 14px;
                            }
                        }
                    }
                }
            }
    
    
        }
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                li{
                    &:nth-of-type(1){
                        margin-right:2rem;
                    }
                    &:nth-of-type(3){
                        width: 200px;
                        margin-right:1rem;
                    }
                    &:nth-of-type(2){
                        width: 300px;
                    }
                    .Box{
                        width:250px;
                    }
                }
            }
            .Footer_Bottom{
                width: 100%;
            }
        }
    }
}

@media (max-width:400px){
    .Footer_Wrapper{
        .Inner_Container{
            width:95%;
            ul{
               gap:1rem;
                flex-direction: column;
                li{
                   width: 100%;
                //    &:last-child{
                    
                //     p{
                //         width: 100%;
                //         border: 1px solid red;
                //     }
                //    }
                    .Box{
                        width:100%;
                    }
                }
            }
            .Footer_Bottom{
                align-items: flex-start;
                flex-direction: column;
                .Left{
                    width: 100%;
                    margin-bottom: 1rem;
                }
                .Right{
                    width: 100%;
                }
            }
        }
    }
}
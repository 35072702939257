@import './mixin.scss';
@import './variables.scss';


.PluginSection{
    width: 100%;
    padding: 8.56rem 5.94rem 14rem 7.56rem;
    @include flex;
    gap: 7.69rem;

    p {
        font-family: 'Manjari';
    }
    
    .Left {
        .Illustrate {
            width: 24.4rem;
        }
    }

    .Right {
        .PluginHeader {
            color: #2563A3;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            font-family: Rockwell;
            line-height: normal; 
            margin-bottom: 2.94rem;
        }

        

        .TopText {
            margin-bottom: 3.38rem;
        }

        .PluginBannerContain {
            margin-top: 3.31rem;
            @include flex;
            gap: 1.19rem;

            .PluginBanner {
                border-radius: 1rem;
                background: #EAF0F9;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                @include flex;
                width: 50%;
                height: 3.625rem; 

                img {
                    transform: scale(0.7);
                }

            }
        }
    }
    
}

@media (max-width:768px){
    .PluginSection{
        display: block;
        padding: 3rem .6rem;

        .Left {
            margin-bottom: 3rem;
            .Illustrate {
                width: 100%;
            }
        }

        .Right {
            .PluginHeader {
                color: #2563A3;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal; 
                margin-bottom: 1.94rem;
                padding: 0 1rem;
            }

            p {
                padding: 0 1rem;
            }
    
            
    
            .TopText {
                margin-bottom: 3.38rem;
            }

            .PluginBannerContain {
                margin-top: 3.31rem;
                @include flex;
                padding: 0 1rem;
                gap: 1.19rem;

                .PluginBanner {
                    border-radius: 1.125rem;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    @include flex;
                    width: 50%;

                    img {
                        transform: scale(0.5);
                    }

                }
            }
        }
    }
}
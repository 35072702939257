@import './mixin.scss';
@import './variables.scss';

.TextBox {
    label {
        display: block;
        margin-bottom: .2rem;
        color: #000;
        font-family: Manjari;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    input {
        width: 100%;
        border-radius: 0.9375rem;
        border: 1px solid #2563A3;
        height: 2.9875rem; 
        background: #FFFCFC;    
        padding: 0 1rem;
    }

    textarea {
        width: 100%;
        border-radius: 0.9375rem;
        border: 1px solid #2563A3;
        background: #FFFCFC;    
        padding: 1rem;
    }
}

.selectBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
}

.selectInput {
    position: relative;
    width: 100%;
    z-index: 3;
}

.selectDropdown {
    background-color: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: .375rem;
    position: absolute;
    top: 3rem;
    margin-top: 1.5rem;
    max-height: 10rem;
    overflow-x: hidden;
    width: 100%;
    overflow-y: auto;
    z-index: 4;
}

.selectList {
    list-style: none;
    padding-left: .75rem;
    padding-right: .75rem;

    li {
        padding-top: .5rem;
        padding-bottom: .5rem;
        border: none;
    }

    .border-b {
        border-bottom: 1px solid #EDEDED;
    }
}

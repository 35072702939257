@import './mixin.scss';
@import './variables.scss';

button{
    @include BtnText;
    color: $white_color;
    // background-color: $blue_color;
    box-sizing: border-box;
    height: 34px;
    padding:.5rem .8rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    transition: all .3s ease-in-out;
    cursor:pointer;
    &:hover{
        background-color: $powder_blue!important;
        color: $black_color;
    }
}
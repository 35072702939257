@import './mixin.scss';
@import './variables.scss';


.PartnerSection{
    width: 100%;
    padding: 6.12rem 0 4.75rem 0;
    background-image: url("../Img/THEWORLD.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #EAF0F9;
    min-height: 890px;

    p {
        font-family: 'Manjari';
    }

    .Title {
        color: #2563A3;
        font-size: 2rem;
        font-family: Rockwell;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        text-align: center;
    }

    .SubTitle {
        font-size: 1rem;
        text-align: center;
    }

    .Sponsors {
        // padding: 0 0 0 8rem;
        margin-top: 1.62rem;
    }
    .LogoContain {
        width: 100%;
        @include flex;
        margin-bottom: 5.81rem;

        img {
            width: 7rem;
            height: 7rem;
        }
    }

    .GridShift {
        padding-left: 6rem;
    }

    .PartnerGrid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 3.37rem;
        margin-left: 6rem;

        .PartnerContain {
            width: 29.5625rem;
            height: 6.75rem; 
            border-radius: 1.5625rem;
            background: #FFFAFA;
            box-shadow: 0px 10px 4px 0px rgba(0, 0, 0, 0.25); 
            padding-left: 1.28rem;
            @include flex;
            justify-content: flex-start;
            gap: 1rem;

            img {
                transform: scale(0.8);
            }

            .PartnerName {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal; 
            }
            .PartnerInfo {
                color: #413D3D;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400; 
            }
        }
    }
    
}

@media (max-width:768px){
    .PartnerSection{
        width:100%;
        min-height: 640px;
        
        .Title {
            font-size: 1.5rem;
        }

        .SubTitle {
            font-size: 1rem;
            padding: 0 1rem;
        }

        .GridShift {
            padding-left: 0;
        }
    
        .PartnerGrid {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            margin-bottom: 2rem;
            row-gap: 2rem;
            margin-left: 1rem;
            margin-right: 1rem;
    
            .PartnerContain {
                width: 100%;
                padding-right: .5rem;
                gap: .5rem;
    
                img {
                    transform: scale(0.8);
                }
    
                .PartnerName {
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal; 
                }
                .PartnerInfo {
                    color: #413D3D;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400; 
                }
            }
        }
    }
}


@mixin CurveEdgeCardText{
font-weight: 500;
font-size: 14px;
line-height: 25px;
}

@mixin CurveCardDetailTitel{
font-weight: 600;
font-size: 20px;
line-height: 20px;
}

@mixin CurveCardDetailSubText{
font-weight: 600;
font-size: 14px;
line-height: 20px;
}

@mixin LogoBg{
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
}

@mixin LandingContainerBodyText{
font-weight: 500;
font-size: 14px;
line-height: 26px;
}

@mixin LandingCardBtnText{
font-weight: 600;
font-size: 10px;
line-height: 15px;
}

@mixin LandCartTitle{
font-weight: 500;
font-size: 14.0663px;
line-height: 24px;
}

@mixin footerTitle{
font-weight: 600;
font-size: 16px;
line-height: 30px;
}

@mixin footerBottomText{
font-weight: 600;
font-size: 12px;
line-height: 137.5%;
}

@mixin setting{
box-sizing: border-box;
overflow: hidden;
width: 100%;
}

@mixin placeholderText{
font-weight: 500;
font-size: 12px;
line-height: 24px;
}

@mixin ConfirtText{
font-weight: 500;
font-size: 12px;
line-height: 20px;
}
@mixin ConfirtTitle{
font-weight: 700;
font-size: 14px;
line-height: 21px;
}

@mixin FormTitle{
font-weight: 600;
font-size: 24px;
line-height: 45px;
}

@mixin HeroTitle{
font-weight: 600;
font-size: 36px;
line-height: 45px;
}

@mixin HeroText{
font-weight: 500;
font-size: 14px;
line-height: 24px;
}
@mixin shipText{
font-weight: 600;
font-size: 10px;
line-height: 24px;
}
@mixin shipTitle{
font-weight: 600;
font-size: 24px;
line-height: 25px;
}

@mixin shipCardTitle{
font-weight: 600;
font-size: 12px;
line-height: 20px;
}
@mixin delivery_man_title{
font-weight: 600;
font-size: 24px;
line-height: 35px;
}
@mixin questionText{
font-weight: 600;
font-size: 12px;
line-height: 10px;
}

@mixin iosText{
font-weight: 700;
font-size: 12px;
line-height: 10px;
}
@mixin SettingDangerBtnText{
font-weight: 600;
font-size: 10px;
line-height: 21px;
}
@mixin SettingTitleText{
font-weight: 500;
font-size: 14px;
line-height: 21px;
}
@mixin SettingBasicText{
font-weight: 700;
font-size: 16px;
line-height: 21px;
}
@mixin SettingChangePWD{
font-weight: 700;
font-size: 12px;
line-height: 18px;
}
@mixin fileText{
font-weight: 500;
font-size: 10px;
line-height: 21px;
}
@mixin fileLabel{
font-weight: 600;
font-size: 16px;
line-height: 21px;
}

@mixin walletBtnText{
font-weight: 700;
font-size: 12px;
line-height: 24px;
border-radius: 3px;
}
@mixin WalletTabHeader{
font-weight: 600;
font-size: 23.9155px;
line-height: 27px;
}
@mixin WalletBoxSubText{
font-weight: 600;
font-size: 7.17465px;
line-height: 10px;
}
@mixin WalletTableHeader{
font-weight: 700;
font-size: 16px;
line-height: 39px;
}
@mixin WalletEmptyHead{
font-weight: 700;
font-size: 18px;
line-height: 39px;
}
@mixin WalletEmptyText{
font-weight: 500;
font-size: 14px;
line-height: 18px;
}
@mixin WalletTableBodyText{
font-weight: 600;
font-size: 14px;
line-height: 21px;
}
@mixin WalletBtnText{
font-weight: 700;
font-size: 10px;
line-height: 12px;
}
@mixin WalletTabText{
font-weight: 700;
font-size: 10.5266px;
line-height: 18px;
}
@mixin WalletTransferText{
font-weight: 500;
font-size: 11.2978px;
line-height: 25px;
}
@mixin flex{
display: flex;
align-items: center;
justify-content: center;
}

@mixin footerText{
font-weight: 600;
font-size: 12px;
line-height: 18px;
}

@mixin FormTinyText{
font-weight: 400;
font-size: 8px;
line-height: 14px;
}

@mixin ForgotText{
font-weight: 400;
font-size: 10px;
line-height: 24px;
}

@mixin LinkText{
font-weight: 600;
font-size: 14px;
line-height: 16px;
}

@mixin DashbaoardWelcomeText{
font-weight: 700;
font-size: 26px;
line-height: 39px;
}
@mixin BalanceText{
font-weight: 600;
font-size: 22.2275px;
line-height: 25px;
}
@mixin BalanceTinyText{
font-weight: 600;
font-size: 6.66825px;
line-height: 9px;
}
@mixin CardTitle{
font-weight: 700;
font-size: 14px;
line-height: 21px;
}

@mixin CardText{
font-weight: 400;
font-size: 12.2069px;
line-height: 20px;
}

@mixin TabSubText{
font-weight: 500;
font-size: 12px;
line-height: 21px;
}


@mixin label{
font-weight: 700;
font-size: 10.494px;
line-height: 18px;
}

@mixin inputText{
font-weight: 400;
font-size: 10.494px;
line-height: 18px;
}

@mixin SubmitText{
font-weight: 700;
font-size: 11.9629px;
line-height: 24px;
}

@mixin PackageText{
font-weight: 700;
font-size: 10.5266px;
line-height: 18px;
}
@mixin PackageNum{
font-weight: 700;
font-size: 12px;
line-height: 21px;
}
@mixin PackageTitle{
font-weight: 700;
font-size: 14px;
line-height: 18px;
}
@mixin PackageSubText{
font-weight: 400;
font-size: 10.5266px;
line-height: 18px;
}

@mixin BtnText{
font-weight: 600;
font-size: 12px;
line-height: 24px;
}
@mixin DangerBtn{
width: 100%;
height: 50px;
margin-top: .5rem;
background-color: $fade_error;
color: $white_color;
font-weight: 700;
font-size: 12px;
line-height: 24px;
border-radius: 3px;
}


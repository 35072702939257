@import './mixin.scss';
@import './variables.scss';

.Home_Header{
    @include setting;
    height: 67px;
    padding:0rem;
    border-bottom: 1px solid rgb(236, 239, 241);
    background-color: #FFF;
    p {
        font-family: 'Manjari';
    }
    .Inner_Container{
        width: 80%;
        height: 100%;
        margin-inline: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .MenuIcon {
        display: none;
    }

    .Header_Link_Contain {
        display: flex;
        align-items: center;
        gap: 2.12rem;

        .Header_Link {
            font-size: .8rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
            color: #000; 
        }

        .Start_Btn {
            padding-left: 1.1rem;
        }
    }

}

@media (max-width:768px){
    .Home_Header{
        .Inner_Container{
            width: 96%;
        }

        .Header_Link_Contain {
            display: none;
        }
    
        .MenuIcon {
            display: block;
            margin-right: 1rem;
        }
    
    }
}
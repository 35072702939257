@import './mixin.scss';
@import './variables.scss';

.Banner{
    width: 100%;
    height: 196px;
    background-color: $Banner_Bg;
    @include flex;
    gap:3.5rem;
    overflow: hidden;
    margin-top:12rem;
}

@media (max-width:768px){
    .Banner{
        gap:2.3rem;
    }
}

@media (max-width:400px){
    .Banner{
        height: auto;
       flex-direction: column;
       gap:4rem;
       padding:2rem 0rem;
    }
}
@import './mixin.scss';
@import './variables.scss';

.Gradient_Wrapper{
    width: 100%;
    height: 316.39px;
    background:$linear_gradient;
    @include flex;
    gap:10rem;
    .Phone,.Appstore,.Googlestore{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .Left{
        h4{
            @include delivery_man_title;
            color: $white_color;
        }
        p{
            @include LandingContainerBodyText;
            color: $white_color;
        }
        .Btn_Wrap{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            margin-top: 1rem;
            .Appstore,.Googlestore{
                width: 116.54px;
                height: 40.15px;
            }
            .Appstore{
                background-image: url("../Img/appstore.png");
            }
            .Googlestore{
                background-image: url("../Img/googlestore.png");
            }
        }
    }
    .Phone{
        align-self: flex-end;
        width: 282.18px;
        height: 250px;
        background-image:url("../Img/phone.png");
        filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.11));
    }
}

@media (max-width:768px){
    .Gradient_Wrapper{
        overflow: hidden;
        gap:2rem;
    }
}

@media (max-width:400px){
    .Gradient_Wrapper{
        flex-direction: column;
        height: auto;
        justify-content: center;
        padding:4rem 1.5rem;
        padding-bottom: 0rem;
        gap:2rem;
        .Left{
            width: 100%;
            text-align: center;
            .Btn_Wrap{
                justify-content: center;
            }
        }
        .Phone{
            align-self: center;
        }
    }
}
@import './mixin.scss';
@import './variables.scss';

.Home_Hero_Wrapper{
    width: 100%;
    min-height: 88vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDF6F5;
    position: relative;
    // padding: 153px 0 219px 0;
    gap: 3rem;
    // border: 1px solid red;
    margin-top: 0rem;
    padding: 60px 0 120px 0;
    p {
        font-family: 'Manjari';
    }
    .arrowIllustrate {
        position: absolute;
        bottom: 10%;
        width: 164.81px;
        height: 226.45px;
        left: 40%;
        z-index: 0;
    }
    .Left{
        width: 40%;
        h3{
            color: #2563A3;
            font-family: Rockwell;
            font-size: 2.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
        }
        .Text_Wrap{
            margin-top: 1rem;
            margin-bottom: 2rem;
            p{
                @include HeroText;
                color: $grey_color;
            }
        }
        .Btn{
            @include flex;
            @include BtnText;
            width: 166.6px;
            height: 35px;
            border-radius: 3px;
            background-color: $deep_cancel;
            color: $white_color;
            cursor: pointer;
        }

        .BtnContain {
            @include flex;
            justify-content: flex-start;
            gap: .4rem;
        }

        .BtnContactSales {
            background-color: transparent;
            border: none;
            color: #413D3D;
        }

    }
    .Right{
        width: 40%;
        height: 550px;
        .Delivery_Wrapper{
            position: relative;
            width: 100%;
            height: 80%;
            .Bg_Color{
                position: absolute;
                bottom: 0%;
                left:20%;
                width: 300px;
                height:430px;
                border-radius: 14.3309px;
                background-color:$blue_color;
            }
            .ImgIllustrate {
                height: 85%;
            }
            .Little_Card{
                width: 220px;
                height: 95.3px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                background-color:$white_color;
                border-radius: 6px;
                position: absolute;
                top: 35%;
                left: 0%;
                padding-inline: 1rem;
                box-shadow: 0px 25px 50px -12px $card_shadow_color;
                p{
                    @include HeroText;
                    color: $grey_color;
                }
            }
            .Badge{
                background-color: $deep_dark;
                width: 91.84px;
                height: 95.3px;
                border-radius: 6px;
                position: absolute;
                right: 10%;
                top: 37%;
               .wrap{
                width: 100%;
                height: 100%;
                position: relative;
                img{
                    width: 50px;
                    height: 50px;
                    position:absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
               }
            }
            .First_White_Badge,.Second_White_Badge{
                width: 75px;
                height: 75px;
                border-radius: 6px;
                background-color: $white_color;
                position: absolute;
                box-shadow: 0px 25px 50px -12px $card_shadow_color;
            }
            .First_White_Badge{
                left: 25%;
                bottom: 13%;
            }
            .Second_White_Badge{
                right:0%;
                bottom:5%;
            }
            .Fade_Orange{
                position: absolute;
                top:28%;
                left:12%;
                width: 101.11px;
                height: 106.09px;
                opacity: 0.3;
                border-radius: 14.3309px;
                background-color: $fade_orange;
            }
            img{
                width: 421.3px;
                height: 528.79px;
                position: absolute;
                right:0%;
                bottom: 0%;
            }
        }
    }  
}

@media (max-width:768px){
    .Home_Hero_Wrapper{
        width:95%;
        margin-inline:auto;
        flex-direction: column-reverse;
        padding: 260px 16px 40px 16px;
        gap:2rem;
        // background-color: red;
        min-height: 100vh;
        .arrowIllustrate {
            display: none;
        }
        .Left{
            width:95%;

            .Text_Wrap{
                margin-bottom: .5rem;
                p{
                    @include HeroText;
                    color: $grey_color;
                }
            }
        }
        .Right{
            width: 100%;
            height: 100px;
            .Delivery_Wrapper{
                .Little_Card{
                    width: 240px;
                }
                .Badge{
                    right:30%;
                }
                .Second_White_Badge{
                    right:25%;
                }
                .ImgIllustrate {
                    height: auto;
                    width: 100%;
                }
            }
        }  
    }
}
// @media (max-width:560px){
//     .Home_Hero_Wrapper{
//         width: 95%;
//         padding: 20px 0 40px 0;
//         height:70vh;
//         margin-top:1rem;

//         .Left{
//             width:95%;
//             margin-top:0;
//         }
//         .Right{
//             width: 100%;
//             .Delivery_Wrapper{
//                 .Bg_Color{
//                     position: absolute;
//                     bottom: 0%;
//                     left:50%;
//                     transform: translate(-50%);
//                 }
//                 .Little_Card{
//                     width: 250px;
//                     height: 95.3px;
//                     top: 80%;
//                     left: 50%;
//                    transform: translateX(-50%);
//                 }
//                 .Badge{
//                     right:10%;
//                     top: 37%;
//                 }
//                 .First_White_Badge,.Second_White_Badge{
//                     display: none;
//                 }
//                 .Fade_Orange{
//                     top:18%;
//                     left:0%;
//                 }
//                 img{
//                     width: 350px;
//                     height: 500px;
//                     right:50%;
//                     transform: translateX(50%);
//                 }
//             }
//         }  
//     }
// }

// @media (max-width:414px){
//     .Home_Hero_Wrapper{
//         width: 95%;
//         padding: 20px 0 40px 0;
//         height:100vh;
//         margin-top:0rem;

//         .Left{
//             width:95%;
//             margin-top:2rem;
//         }
//         .Right{
//             width: 100%;
//             .Delivery_Wrapper{
//                 .Bg_Color{
//                     position: absolute;
//                     bottom: 0%;
//                     left:50%;
//                     transform: translate(-50%);
//                 }
//                 .Little_Card{
//                     width: 250px;
//                     height: 95.3px;
//                     top: 80%;
//                     left: 50%;
//                    transform: translateX(-50%);
//                 }
//                 .Badge{
//                     right:10%;
//                     top: 37%;
//                 }
//                 .First_White_Badge,.Second_White_Badge{
//                     display: none;
//                 }
//                 .Fade_Orange{
//                     top:18%;
//                     left:0%;
//                 }
//                 img{
//                     width: 350px;
//                     height: 500px;
//                     right:50%;
//                     transform: translateX(50%);
//                 }
//             }
//         }  
//     }
// }